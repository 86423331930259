import React from "react"
import Layout from "../components/Layout"
import imageJoe1 from "../images/photo-joe-buttonup.jpg"
import imageJoe2 from "../images/photo-joe-football.jpg"

export default () => {
  return (
    <Layout>
      <main className="max-w-4xl flex-grow px-6 lg:px-0 md:mx-auto flex flex-col">        
        <div>
          <h2 className="text-cornflower-500 font-display font-bold text-6xl mb-4">Live Like Joe Community Legacy</h2>

          <div className="flex flex-col md:flex-row">
            <div className="md:w-2/3">
              <p className="text-xl font-light leading-relaxed mt-6 mb-4">
                Joe Little never met a stranger. He was a rare young man that was generous with his time and support.  He used his charisma and kindness to motivate and make anyone feel good about themselves.  A new student showed up in his class one day and was feeling very awkward and anxious.  Joe went over to him, introduced himself welcome him to the school.  This  simple kind gesture changed the new student’s perspective immediately and he let his guard down and realized he was in the right place  He credits Joe for making him feel like a part of his new community.
              </p>

              <p className="text-xl font-light leading-relaxed mt-0 mb-4">The outpouring from the community upon hearing the news of Joe’s passing was “universally” devastating.  Immediately the students organized a vigil for him right after he passed so the students had somewhere to go and grieve together to support each other.  Hundreds of students showed upit was standing room only, those who could not get in the chapel packed themselves into the “quad”, upwards of 400 were students in attendance.</p>
            </div>
            <div className="md:ml-8 flex items-center">
              <img
                  className="sm:max-w-sm"
                  src={imageJoe1}
                  alt="Joe"
                />
            </div>
          </div>

          <div className="py-6 px-2 rounded-lg bg-scarlet-100 text-scarlet-400 font-display font-bold text-4xl md:text-6xl text-center my-12">
            <span className="inline-block">"Let’s go it’s game day"</span>
            <span className="inline-block">&mdash; Joe Little</span>
          </div>


          <div className="flex flex-col md:flex-row">
            <div className="md:w-2/3 md:order-2">
              <p className="text-xl font-light leading-relaxed mt-0 mb-4">Joe would take the time to listen to you and he was also a motivator.  His impact on “students/etc” had a wide breadth.  He was known to call or text players before they were getting ready to play and he’d say “Let’s go it’s game day” to inspire them.  He reached out to a wide network and his impact was felt by so many.  He would text back and forth with his teammates always approaching things with an energy that was infectious.  He met a University of Houston football player while playing video games and they became close, upon learning of Joe’s he passing sent a signed football glove to honor Joe as a token of his grief.  Joe also kept a running group text with his Grandpa and Grand Uncle Butch on his Mothers side which was known to have been hilarious.</p>
              <p className="text-xl font-light leading-relaxed mt-0 mb-4">Joe had countless friends and was loved by all young and old.  He gave of himself freely and happily and never asked for anything.  He was a true southern gentleman and a proud Texan.  And for those of us blessed to have had him in our lives, we know that he was all of these things and more.  He was one of ours that left a profound impact and lasting memories.  He was sweet, funny, caring, kind, humble, and irreplaceable.</p>
            </div>
            <div className="md:order-1 md:mr-8 flex items-center">
              <img
                  className="sm:max-w-sm"
                  src={imageJoe2}
                  alt="Joe"
                />
            </div>
          </div>
        </div>
      </main>
    </Layout>
  )
}
